export const pageList = {
  home: {
    url: '/',
  },
  blog: {
    url: '/blog',
  },
  game: {
    url: '/upcoming-game',
  },
};

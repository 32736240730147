const LANG_EN = 'en';
const LANG_ZH = 'zh';

const DEFAULT_LANG = LANG_ZH;

const LANG_LIST = [LANG_EN, LANG_ZH];

exports.LANG_EN = LANG_EN;
exports.LANG_ZH = LANG_ZH;
exports.DEFAULT_LANG = DEFAULT_LANG;

exports.LANG_LIST = LANG_LIST;
